/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Tab, Tabs, useMediaQuery, useTheme } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const ResponsiveTabs = ({
  categories,
  scrollToRef,
  activeTab,
  handleChange,
}) => {
  const theme = useTheme();

  const tabStyles = css`
    min-width: 200px;
    
    ${theme.breakpoints.down("md")} {
      min-width: 160px;
    }
    ,
    ${theme.breakpoints.down("sm")} {
      min-width: 100px;
    }
    ,
    ${theme.breakpoints.down("xs")} {
      min-width:80px;
    }
    ,
  `;

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const tabProps = {
    // variant: isSmallScreen ? "scrollable" : "standard",
    centered: !isSmallScreen ? true : false,
    // scrollButtons: isSmallScreen ? "on" : "auto",
  };

  return (
    <Tabs
      value={activeTab}
      indicatorColor="primary"
      text-color="primary"
      onChange={handleChange}
      {...tabProps}
    >
      {categories.map((category) => (
        <Tab
          css={tabStyles}
          component={RouterLink}
          to={(location) => ({
            pathname: `/${category.url}`,
          })}
          key={category.id}
          label={category.name}
          onClick={scrollToRef}
        />
      ))}
    </Tabs>
  );
};

export default ResponsiveTabs;
