/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Grid, useTheme } from "@mui/material";

// import Button from "../../atoms/Button";
import Typography from "../../atoms/Typography";

const MainHeading = () => {
  const theme = useTheme();

  const container = css`
    justify-content: center;
    display: flex;
    direction: column;
  `;
  const centerText = css`
    margin: 0 auto;
    text-align: center;
  `;
  const textContainer = css`
    margin-bottom: ${theme.spacing(4)};
  `;

  const borderedHeading = css`
    border-bottom: 12px solid ${theme.palette.primary.main};
    line-height: 55px;
    padding-bottom: ${theme.spacing(2)};
  `;

  const subHeadingContainer = css`
    margin: ${theme.spacing(2)} 0;
  `;

  return (
    <Grid container css={container}>
      <Grid item css={centerText}>
        <div css={textContainer}>
          <Typography variant="h1">Welcome to</Typography>
          <Typography variant="h1" styling={borderedHeading}>
            StreamLookout
          </Typography>
          <div css={subHeadingContainer}>
            <Typography variant="h2">Your Live CamGirl Feed</Typography>
          </div>
        </div>
        {/* <Button variant="contained" color="primary" size="large">
          Sign Up
        </Button> */}
      </Grid>
    </Grid>
  );
};

export default MainHeading;
