import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@mui/material";

import common from "./common";

const darkProps = {
  MuiAppBar: {
    color: "secondary",
  },
  palette: {
    mode: "dark",
    primary: {
      main: "#f8809a",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#000000",
    },
  },
};

const overRides = {
  overrides: {
    MuiSwitch: {
      switchBase: {
        color: "#dbdbdb",
      },
      colorSecondary: {
        "&$checked": {
          color: darkProps.palette.primary.main,
        },
      },
      track: {
        // Controls default (unchecked) color for the track
        opacity: 0.2,
        // get backgroundColor() {
        //   // return this.palette.primary.main;
        //   // console.log("getter");
        //   return "#ffcc00";
        // },
        "$checked$checked + &": {
          // Controls checked color for the track
          opacity: 0.7,
          backgroundColor: darkProps.palette.primary.main,
        },
      },
    },
  },
};

const mergedTheme = Object.assign(common.props, darkProps, overRides);

const theme = createMuiTheme({
  ...common,
  ...mergedTheme,
});

export default theme;
