import { Link as MuiLink } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const Link = ({ children, className, to, color = "primary", ...rest }) => {
  return (
    <MuiLink
      component={RouterLink}
      to={to}
      color={color}
      underline="none"
      className={className}
      {...rest}
    >
      {children}
    </MuiLink>
  );
};

export default Link;
