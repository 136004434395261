function getCategoryId(categoryName) {
  switch (categoryName) {
    case "girls":
      return 0;
    case "boys":
      return 1;
    case "trans":
      return 2;
    default:
      return 0;
  }
}

function upperCaseFirstLetter(s) {
  return s.replace(/./, (c) => c.toUpperCase());
}

export { getCategoryId, upperCaseFirstLetter };
