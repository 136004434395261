import { createContext, useContext } from "react";

import SessionStore from "./sessionStore";
import UIStore from "./uiStore";

class RootStore {
  constructor() {
    this.sessionStore = new SessionStore(this);
    this.uiStore = new UIStore(this);
  }
}

const StoreContext = createContext();

export const StoreProvider = ({ store, children }) => {
  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};

export const useStore = () => {
  return useContext(StoreContext);
};

export default RootStore;
