import { ReactComponent as SmallIndicator } from "../../../assets/small_indicator.svg";

const StatusIndicator = ({ status, ...props }) => {
  let color;

  switch (status) {
    case "free_chat":
      color = "rgb(71, 218, 16)";
      break;
    case "member_chat":
      color = "rgb(218,16,16)";
      break;
    case "offline":
      color = "rgb(160,160,160)";
      break;
    case "private_chat":
      color = "rgb(218,16,16)";
      break;
    case "test":
      color = "rgb(248,128,154)";
      break;
    default:
      color = "rgb(248,128,154)";
      break;
  }

  return <SmallIndicator {...props} style={{ color: color }} />;
};

export default StatusIndicator;
