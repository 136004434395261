import debounce from "lodash/debounce";
import { makeAutoObservable, observable } from "mobx";

import getTheme from "../themes/base";

const breakPoints = {
  xs: 0,
  sm: 375,
  md: 744,
  lg: 950,
  xl: 1128,
};

const RESIZE_TIMEOUT = 100;
const SCROLL_TIMEOUT = 100;

class UIStore {
  themeSettings = {
    type: "light",
    mode: "manual",
    theme: "",
    state: "initial",
  };
  // .struct makes sure observer won't be signaled unless the
  // dimensions object changed in a deepEqual manner.
  windowDimensions = {
    width: window.innerWidth,
    height: window.innerHeight,
  };
  screenOrientation = null;
  userAgent = null;
  screenSize = null;
  secondaryNavActive = false;

  constructor(rootStore) {
    makeAutoObservable(this, {
      rootStore: false,
      attachEventListeners: false,
      attachResizeListener: false,
      removeEventListener: false,
      removeResizeListener: false,
      calculateBreakpoint: false,
      hasWindowObj: false,
      init: false,
      initScreenSize: false,
      initTheme: false,
      initUserAgent: false,
      windowDimensions: observable.struct,
    });
    this.init();
  }

  init() {
    this.initTheme();
    this.initScreenSize();
    this.initUserAgent();
    this.attachEventListeners();
  }

  initTheme() {
    const currentTheme = JSON.parse(
      localStorage.getItem("appTheme") || '{"type":"light"}'
    );
    this.setTheme(currentTheme.type);
  }

  initScreenSize = () => {
    if (typeof window !== "undefined") {
      const width = window.innerWidth;
      const height = window.innerHeight;
      const breakpoint = this.calculateBreakpoint(width);
      // const orientation = this.calculateOrientation(width, height);

      this.setScreenSize(breakpoint);
      this.setWindowDimensions(width, height);
    }
  };

  initUserAgent() {
    // if (this.hasWindowObj && navigator) {
    //   const isAppleMobile =
    //   const isAppleMac =
    //   const isAndroid =
    //   const isWindows =
    // }
    // console.log("UA size initialized");
  }

  initSecondaryNav = () => {
    if (typeof window !== "undefined") {
      const triggerPoint = this.calculateScrollPosition();

      this.setSecondaryNavActive(triggerPoint);
      this.removeScrollListener();
    }
  };

  attachEventListeners() {
    this.attachResizeListener();
    this.attachScrollListener();
  }

  attachResizeListener() {
    const resizeFunc = debounce(this.initScreenSize, RESIZE_TIMEOUT);
    window.addEventListener("resize", resizeFunc);
  }

  attachScrollListener() {
    const scrollFunc = debounce(this.initSecondaryNav, SCROLL_TIMEOUT);
    window.addEventListener("scroll", scrollFunc);
  }

  removeEventListeners() {
    if (this.hasWindowObj) {
      this.removeResizeListener();
      this.removeScrollListener();
    }
  }

  removeResizeListener() {
    const resizeFunc = debounce(this.initScreenSize, RESIZE_TIMEOUT);
    window.removeEventListener("resize", resizeFunc);
  }

  removeScrollListener() {
    const scrollFunc = debounce(this.initScreenSize, SCROLL_TIMEOUT);
    window.removeEventListener("scroll", scrollFunc);
  }

  hasWindowObj() {
    return typeof window !== "undefined";
  }

  calculateBreakpoint = (width) => {
    if (width < breakPoints["sm"]) {
      return "xs";
    } else if (width <= breakPoints["md"]) {
      return "sm";
    } else if (width <= breakPoints["lg"]) {
      return "md";
    } else if (width <= breakPoints["xl"]) {
      return "lg";
    } else return "xl";
  };

  calculateScrollPosition = () => {
    return window.pageYOffset > 350 ? true : false;
  };

  get isMobile() {
    return this.hasWindowObj
      ? this.screenSize === "xs" || this.screenSize === "sm"
      : false;
  }

  get isSecondaryNavActive() {
    return this.secondaryNavActive;
  }

  setScreenSize = (breakpoint) => {
    this.screenSize = breakpoint;
  };

  setSecondaryNavActive(isActive) {
    this.secondaryNavActive = isActive;
  }

  setTheme(themeType) {
    this.themeSettings.state = "loading";
    const theme = getTheme(themeType);
    this.themeSettings.type = themeType;
    this.themeSettings.theme = theme;
    this.themeSettings.state = "loaded";
  }

  setWindowDimensions = (width, height) => {
    this.windowDimensions = {
      width: width,
      height: height,
    };
  };
}

export default UIStore;
