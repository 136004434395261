/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useTheme } from "@mui/material";
import { useState } from "react";

import Link from "../atoms/Link";
import Typography from "../atoms/Typography";

const SecondaryMenu = ({ categories, activeTab, handleChange }) => {
  const theme = useTheme();

  const [active, setActive] = useState(activeTab);

  const handleClick = (value) => {
    setActive(value);
    handleChange(null, value);
  };

  const activeStyle = css`
    color: ${theme.palette.primary.main};
  `;

  return categories.map((category, index) => (
    <StyledLink
      to={`/${category.url}`}
      key={category.id}
      theme={theme}
      css={active === index ? activeStyle : null}
      value={active}
      onClick={() => handleClick(index)}
    >
      <Typography variant="h6">{category.name}</Typography>
    </StyledLink>
  ));
};

export default SecondaryMenu;

const StyledLink = styled(Link)`
  min-width: 160px;
  text-transform: uppercase;
  color: white;
  ${(props) => props.theme.breakpoints.down('md')} {
    min-width: 100px;
  }
  ${(props) => props.theme.breakpoints.down('sm')} {
    min-width: 80px;
  }
`;
