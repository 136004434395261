import axios from "axios";

const API_ROOT = "https://jsonplaceholder.typicode.com";

// Mock store for later use if needed
const store = {
  isAuthenticated: false,
  accessToken:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c",
};

const getClient = (baseUrl) => {
  const options = {
    baseURL: baseUrl,
  };

  // TODO: Add store for user authentication
  // Check if user is authenticated from a potential user store
  // If yes, add the token to the header
  if (store.isAuthenticated) {
    options.headers = {
      Authorization: `Bearer ${store.accessToken}`,
    };
  }

  const client = axios.create(options);

  // Request interceptor to add logging, catching errors, etc
  client.interceptors.request.use(
    (requestConfig) => requestConfig,
    (requestError) => {
      // TODO: sentry.log(requstError);
      console.error(requestError);
      return Promise.reject(requestError);
    }
  );

  // Response interceptor to add logging, catching errors, etc
  client.interceptors.response.use(
    (response) => {
      if ((response.status === 200) | (response.status === 201)) {
        // Do something here with successful responses
      }
      return response;
    },
    (error) => {
      // Handle errors here, for example:
      if (error.response.status >= 500) {
        // Log to sentry
      }
      console.error(error);

      return Promise.reject(error);
    }
  );

  return client;
};

class APIClient {
  constructor(baseUrl) {
    this.client = getClient(baseUrl);
  }

  get(url, conf = {}) {
    return this.client
      .get(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  }

  delete(url, conf = {}) {
    return this.client
      .delete(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  }

  head(url, conf = {}) {
    return this.client
      .head(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  }

  options(url, conf = {}) {
    return this.client
      .options(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  }

  post(url, conf = {}) {
    return this.client
      .post(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  }

  put(url, conf = {}) {
    return this.client
      .put(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  }

  patch(url, conf = {}) {
    return this.client
      .patch(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  }
}

const APIService = new APIClient(API_ROOT);
export { APIService };

/**
 * Base HTTP Client
 */

const baseHTTPClient = {
  // Provide request methods with the default baseUrl
  get(url, conf = {}) {
    return getClient()
      .get(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  delete(url, conf = {}) {
    return getClient()
      .delete(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  head(url, conf = {}) {
    return getClient()
      .head(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  options(url, conf = {}) {
    return getClient()
      .options(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  post(url, conf = {}) {
    return getClient()
      .post(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  put(url, conf = {}) {
    return getClient()
      .put(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  patch(url, conf = {}) {
    return getClient()
      .patch(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
};

export default baseHTTPClient;
