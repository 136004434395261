/** @jsxImportSource @emotion/react */

import { Typography as MuiTypography } from "@mui/material";

const Typography = ({ children, styling, variant = "body1", ...rest }) => {
  return (
    <MuiTypography variant={variant} css={styling} {...rest}>
      {children}
    </MuiTypography>
  );
};

export default Typography;
