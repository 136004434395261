import { Grid } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";

import baseHTTPClient from "../../services/APIService";
import { getCategoryId, upperCaseFirstLetter } from "../../utils/utils";
import FeedSections from "../elements/organisms/FeedSections";

const ListPage = ({ handleChange }) => {
  const [streamList, setStreamList] = useState({
    girls: [],
    boys: [],
    trans: [],
  });
  const [streams, setStreams] = useState({ girls: [], boys: [], trans: [] });
  const [lastDoc, setLastDoc] = useState({});
  const [loading, setLoading] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);

  let { category } = useParams();
  if (!category) {
    category = "girls";
  }

  const ucCategory = upperCaseFirstLetter(category);

  const loader = useRef(null);

  const getLastFetchedIndex = useCallback(
    (element) => element.name === lastDoc[category].name,
    [lastDoc, category]
  );

  const callFetchStreams = useCallback(async () => {
    if (lastDoc[category]) {
      const lastDocIndex = streamList[category].findIndex(getLastFetchedIndex);

      return streamList[category].slice(lastDocIndex + 1, lastDocIndex + 16);
    }
    if (!streamList[category]) return [];
    return streamList[category].slice(0, 16);
  }, [category, getLastFetchedIndex, streamList, lastDoc]);

  const callUpdateState = useCallback(
    async (data, category) => {
      const isCollectionEmpty = data.length === 0;

      if (!isCollectionEmpty) {
        const lastFetchedDoc = data[data.length - 1];
        const mergedDocs = [...streams[category], ...data];

        setStreams({ ...streams, [category]: mergedDocs });
        setLastDoc({ ...lastDoc, [category]: lastFetchedDoc });
        setIsEmpty(false);
      } else {
        setIsEmpty(true);
      }
      setLoading(false);
    },
    [lastDoc, streams, setStreams]
  );

  const init = useCallback(() => {
    if (streams[category].length === 0) {
      callFetchStreams().then((data) => {
        callUpdateState(data, category);
      });
    }
  }, [callFetchStreams, callUpdateState, category, streams]);

  // const fetchDocument = useCallback(
  //   async (category) => {
  //     const streamsDocRef = doc(firestore, "streams", `livejasmin_${category}`);

  //     const docSnap = await getDoc(streamsDocRef);

  //     if (docSnap.exists()) {
  //       return docSnap.data();
  //     } else {
  //       throw new Error("No such document!");
  //     }
  //   },
  //   [firestore]
  // );

  const handleDataFormatting = useCallback(async (rawData) => {
    if (rawData) {
      return rawData.models.map((element) => ({
        name: element.displayName,
        status: element.status,
        // imageUrl: element.profilePictureUrl.size320x240,
        imageUrl: element.profilePictureUrl.size800x600,
        profileUrl: element.chatRoomUrl,
      }));
    }
  }, []);

  const handleUpdateStreamList = useCallback(
    async (data) => {
      if (streamList[category].length === 0) {
        setStreamList({
          ...streamList,
          [category]: data,
        });
      }
    },
    [category, streamList]
  );

  const fetchDocument = useCallback(
    async (category) => {
      const adjustedCategory = adjustCategory(category);
      const URL = `//pt.ptlwm.com/api/model/feed?siteId=jasmin&psId=blackpearl9&psTool=213_1&psProgram=revs&campaignId=&category=${adjustedCategory}&limit=10000&imageSizes=320x240,800x600&showOffline=0&onlyMobileStreams=0&extendedDetails=0&responseFormat=json&performerId=&subAffId={SUBAFFID}&accessKey=08a779928ca6bf182d9bf839ad4d3a4b&legacyRedirect=1&filters=&customOrder=most_popular`;
      const response = await baseHTTPClient.get(URL);
      return handleDataFormatting(response.data.data);
    },
    [handleDataFormatting]
  );

  const adjustCategory = (category) => {
    if (category === "boys") {
      return "gay";
    } else if (category === "trans") {
      return "transgender";
    } else {
      return "girls";
    }
  };

  useEffect(() => {
    (async () => {
      if (streamList[category].length === 0) {
        try {
          const fetchedStreams = await fetchDocument(category);
          await handleUpdateStreamList(fetchedStreams);
        } catch (error) {
          console.error(error);
        }
      }
      init();
    })();
  }, [fetchDocument, category, init, handleUpdateStreamList, streamList]);

  // Handle Infinite Scrolling
  const loadMore = useCallback(
    (entries) => {
      const target = entries[0];

      if (
        target.isIntersecting &&
        !isEmpty &&
        Math.floor(target.intersectionRatio) === 1
      ) {
        callFetchStreams().then((data) => {
          callUpdateState(data, category);
        });
      }
    },
    [isEmpty, callFetchStreams, category, callUpdateState]
  );

  // useEffect for infinite scroll
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px 0px 0px 0px",
      threshold: 1.0,
    };

    const observer = new IntersectionObserver(loadMore, options);

    if (loader && loader.current) {
      observer.observe(loader.current);
    }

    return () => observer.disconnect();
  }, [loader, loadMore]);

  // Sets the correct active tab on page load
  useEffect(() => {
    handleChange(null, getCategoryId(category));
  }, [handleChange, category]);

  if (streams[category].length === 0) {
    return <p>Fetching streams...</p>;
  }

  return (
    <Grid container>
      <Helmet>
        <title>{ucCategory}</title>
        <meta
          name="description"
          content={`Watch Live ${ucCategory} Cams Now! No Registration Required - Free Adult Chat with ${ucCategory}. Start chatting with amateurs, exhibitionists, pornstarts with HD Video &amp; Audio.`}
        />
        <meta
          name="keywords"
          content={`${ucCategory} webcam sex, free cams, free adult video chat, live adult cams, free sex webcams, adult webcams, live porn, webcam sex`}
        />
      </Helmet>
      <FeedSections streams={streams[category]} />
      <div ref={loader}>{loading && <h2>Loading...</h2>}</div>
    </Grid>
  );
};

export default ListPage;
