import { Grid } from "@mui/material";

import ArticleCard from "./ArticleCard";

const FeedSections = ({ streams }) => {
  return streams.map((stream, index) => {
    return (
      <Grid item xs={6} md={3} key={index}>
        <ArticleCard variant="list" articleItem={stream} />
      </Grid>
    );
  });
};

export default FeedSections;
