import styled from "@emotion/styled";
import { useTheme } from "@mui/material";

import Link from "../atoms/Link";
import StatusIndicator from "../atoms/StatusIndicator";
import Typography from "../atoms/Typography";

const ArticleCard = ({ variant, articleItem }) => {
  // const { guid, name, tags, text, picture, category } = articleItem;
  const { name, imageUrl, site, profileUrl, status } = articleItem;

  const theme = useTheme();

  // const handleClick = () => {
  //   console.log("heart clicked");
  // };

  // TODO: Add some wrapper for clicking whole card...

  return (
    <StyledDiv theme={theme}>
      <StyledFigure theme={theme}>
        <StyledImage theme={theme} src={imageUrl} alt={name} />
      </StyledFigure>
      <StyledLiveIndicatorDiv>LIVE</StyledLiveIndicatorDiv>
      {name && (
        <>
          <StyledStatusIndicator status={status} />

          <StyledNameTypography variant="body1">{name}</StyledNameTypography>
          <StyledPartnerTypography variant="body2" color="textSecondary">
            {site}
          </StyledPartnerTypography>
        </>
      )}
      <Link target="_blank" to={profileUrl}>
        {" "}
      </Link>
    </StyledDiv>
  );
};

export default ArticleCard;

const StyledImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: ${(props) => props.theme.shape.borderRadius};
  border: 1px solid #dedede;
`;

const StyledStatusIndicator = styled(StatusIndicator)`
  display: inline-block;
  vertical-align: middle;
  margin-top: 1px;
`;

const StyledNameTypography = styled(Typography)`
  display: inline-block;
  vertical-align: middle;
  margin-left: 8px;
  // margin-bottom: 8px;
  max-width: 128px;
  font-weight: 600;
  // font-size: 12px;
`;

const StyledPartnerTypography = styled(Typography)`
  margin-bottom: 8px;
  max-width: 128px;
  font-weight: 400;
  text-transform: uppercase;
  // font-size: 12px;
`;

const StyledFigure = styled.figure`
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding-top: 66.66%;
  position: relative;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }
`;

const StyledDiv = styled.div`
  margin-top: ${(props) => props.theme.spacing(1)};
  margin-right: ${(props) => props.theme.spacing(1)};
  margin-left: ${(props) => props.theme.spacing(1)};
  margin-bottom: ${(props) => props.theme.spacing(4)};
  position: relative;
  a {
    z-index: 1;
    width: 0;
    height: 0;
    :after {
      position: absolute;
      content: "";

      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
`;

const StyledLiveIndicatorDiv = styled.div`
  position: absolute;
  background-color: #ff0000;
  color: white;
  border-radius: 3px;
  padding: 0px 3px 0px 3px;
  font-size: 0.6rem;
  top: 8px;
  left: 8px;
  font-weight: 600;
`;
