import { ReactComponent as Logo } from "../../../assets/logo.svg";
import Link from "../atoms/Link";

const Branding = () => {
  return (
    <Link to="/" color="inherit">
      <Logo style={{ width: 250 }} />
    </Link>
  );
};

export default Branding;
