/** @jsxImportSource @emotion/react */
import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { AppBar, Toolbar, useTheme } from "@mui/material";

import SecondaryMenu from "../molecules/SecondaryMenu";

const SecondaryHeader = ({ categories, ...rest }) => {
  const theme = useTheme();

  const appBarStyles = css`
    ${theme.mixins.toolbar}
    top: ${theme.mixins.toolbar.minHeight}px;
    background-color: ${theme.palette.secondary.light};
    z-index: 5;
  `;

  const slideIn = keyframes`
  from {
    top: 0px
  }
  to {
    top: 64px
  }
  `;

  // TODO: Don't animate on click
  return (
    <div>
      <AppBar
        css={css`
          ${appBarStyles}
          animation: ${slideIn} 0.1s ease;
        `}
      >
        <StyledToolBar>
          <SecondaryMenu categories={categories} {...rest} />
        </StyledToolBar>
      </AppBar>
    </div>
  );
};

export default SecondaryHeader;

const StyledToolBar = styled(Toolbar)`
  justify-content: center;
  text-align: center;
`;
