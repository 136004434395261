/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { observer } from "mobx-react-lite";
import { useMemo, useRef } from "react";

import { useStore } from "../../../stores/rootStore";
import ResponsiveTabs from "../molecules/ResponsiveTabs";

const MainTabs = observer(({ headerRef, categories, ...rest }) => {
  const store = useStore();

  const tabsRef = useRef(null);

  const tabStyles = css`
    // position: sticky;
    top: 64px;
    left: 0;
    right: 0;
    z-index: 1;
  `;

  const hiddenTab = css`
    visibility: hidden;
  `;

  const scrollToRef = () => {
    if (!store.uiStore.isSecondaryNavActive) {
      const headerOffset = headerRef.current.getBoundingClientRect().bottom;
      const elementPosition = tabsRef.current.getBoundingClientRect().top;

      const newOffset = elementPosition + window.scrollY - headerOffset;

      window.scrollTo({ top: newOffset });
    }
  };

  const shouldHide = useMemo(() => {
    return store.uiStore.isSecondaryNavActive;
  }, [store.uiStore.isSecondaryNavActive]);

  return (
    <div css={[tabStyles, shouldHide ? hiddenTab : null]} ref={tabsRef}>
      <ResponsiveTabs
        categories={categories}
        scrollToRef={scrollToRef}
        {...rest}
      />
    </div>
  );
});

export default MainTabs;
