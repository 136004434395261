import { Container, CssBaseline } from "@mui/material";
import { ThemeProvider, StyledEngineProvider, responsiveFontSizes } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import { useRef } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Route, Switch, useLocation } from "react-router-dom";

import FirebaseComponents from "../services/FirebaseComponents";
import { useStore } from "../stores/rootStore";
import Footer from "./elements/organisms/Footer/Footer";
import Header from "./elements/organisms/Header/Header";
import DetailPage from "./pages/DetailPage";
import Main from "./pages/Main";

const helmetContext = {};

const App = observer(() => {
  const store = useStore();
  const location = useLocation();

  let theme = store.uiStore.themeSettings.theme;
  theme = responsiveFontSizes(theme);

  let background = location.state && location.state.background;

  const headerRef = useRef();

  return (
    <FirebaseComponents>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline>
            <HelmetProvider context={helmetContext}>
              <Helmet
                titleTemplate="%s - Stream Lookout - Find the Best WebCam Models"
                defaultTitle="Stream Lookout"
              >
                <meta
                  name="description"
                  content="Watch Live Cams Now! No Registration Required - Free Adult Chat. Start chatting with amateurs, exhibitionists, pornstarts with HD Video &amp; Audio."
                />
                <meta
                  name="keywords"
                  content="webcam sex, free cams, free adult video chat, live adult cams, free sex webcams, adult webcams, live porn"
                />
              </Helmet>
              <Header headerRef={headerRef} />
              <Container style={{ maxWidth: 1600 }}>
                <Switch location={background || location}>
                  {/* <Route exact path="/login" children={<Login />} />
              <Route exact path="/signup" children={<Signup />} />
              <PrivateRoute exact path="/profile" component={Profile} /> */}
                  <Route
                    exact
                    path="/:category/:model"
                    render={() => <DetailPage />}
                  />
                  <Route path="/" render={() => <Main headerRef={headerRef} />} />
                </Switch>
                {/* {background && (
              <Route exact path="/login" children={<Login isModal="true" />} />
            )}
            {background && (
              <Route exact path="/signup" children={<Signup isModal="true" />} />
            )} */}
                <Footer />
              </Container>
            </HelmetProvider>
          </CssBaseline>
        </ThemeProvider>
      </StyledEngineProvider>
    </FirebaseComponents>
  );
});

export default App;
