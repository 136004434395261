import { makeAutoObservable } from "mobx";

class SessionStore {
  authUser = "";
  authState = "initial";
  authError = "";
  isLoggedIn = false;

  constructor(rootStore) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;
  }

  setAuth(username) {
    this.authState = "loading";
    this.authUser = username;
    this.isLoggedIn = true;
    this.authState = "loaded";
  }

  removeAuth() {
    this.authState = "loading";
    this.authUser = null;
    this.isLoggedIn = false;
    this.authState = "loaded";
  }
}

export default SessionStore;
