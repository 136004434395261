// import { getAuth } from "@firebase/auth";
import { getAnalytics } from "@firebase/analytics";
import { getFirestore } from "@firebase/firestore";
import {
  AnalyticsProvider,
  FirestoreProvider,
  useFirebaseApp,
} from "reactfire";

function FirebaseComponents({ children }) {
  const app = useFirebaseApp(); // Parent component should contain FirebaseAppProvider

  // Initialize Firestore with the normal Firebase SDK functions
  const database = getFirestore(app);
  const analytics = getAnalytics(app);
  // const auth = getAuth(app);

  // Any child components fill be able to use 'useUser', useFirestoreObjectData, etc
  return (
    // <AuthProvider sdk={auth}>
    <AnalyticsProvider sdk={analytics}>
      <FirestoreProvider sdk={database}>{children}</FirestoreProvider>
    </AnalyticsProvider>
    // </AuthProvider>
  );
}

export default FirebaseComponents;
