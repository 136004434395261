import styled from "@emotion/styled";

import Link from "../atoms/Link";

const d = new Date();
const year = d.getUTCFullYear();

const FooterLinks = () => {
  return (
    <>
      <StyledFooterDiv>
        <StyledFooterLink variant="body2" to="about">
          About Us
        </StyledFooterLink>
        <StyledFooterLink variant="body2" to="terms">
          Terms
        </StyledFooterLink>
        <StyledFooterLink variant="body2" to="help">
          Help
        </StyledFooterLink>
        <StyledFooterLink variant="body2" to="sitemap">
          Sitemap
        </StyledFooterLink>
      </StyledFooterDiv>
      <StyledFooterDiv>
        <Link variant="caption" to="home">
          &#169; {year} StreamLookout
        </Link>
      </StyledFooterDiv>
    </>
  );
};

export default FooterLinks;

const StyledFooterDiv = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledFooterLink = styled(Link)`
  margin-left: ${(props) => props.theme.spacing(2)};
  margin-right: ${(props) => props.theme.spacing(2)};
`;
