import styled from "@emotion/styled";
import { useTheme } from "@mui/material";

import MainContent from "../elements/organisms/MainContent";
import MainHeading from "../elements/organisms/MainHeading/MainHeading";

const Main = ({ headerRef }) => {
  const theme = useTheme();

  return (
    <>
      <StyledHeader theme={theme}>
        <MainHeading />
      </StyledHeader>
      <main>
        <MainContent headerRef={headerRef} />
      </main>
    </>
  );
};

export default Main;

const StyledHeader = styled.header`
  margin-top: ${(props) => props.theme.spacing(10)};
  margin-bottom: ${(props) => props.theme.spacing(8)};
`;
