import styled from "@emotion/styled";
import { useTheme } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useMemo, useState } from "react";
import { Route, Switch } from "react-router-dom";

import { useStore } from "../../../stores/rootStore";
// import ListPage from "../../pages/ListPage";
import SimpleListPage from "../../pages/SimpleListPage";
import MainTabs from "./MainTabs";
import SecondaryHeader from "./SecondaryHeader";

const MainContent = observer(({ headerRef }) => {
  const store = useStore();
  const theme = useTheme();

  const shouldShowSecondaryNav = useMemo(() => {
    return store.uiStore.isSecondaryNavActive;
  }, [store.uiStore.isSecondaryNavActive]);

  const [activeTab, setActiveTab] = useState(0);
  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <>
      <MainTabs
        headerRef={headerRef}
        categories={categories}
        activeTab={activeTab}
        handleChange={handleChange}
      />
      {shouldShowSecondaryNav && (
        <SecondaryHeader
          headerRef={headerRef}
          categories={categories}
          activeTab={activeTab}
          handleChange={handleChange}
        />
      )}
      <StyledDiv theme={theme}>
        <Switch>
          {/* // TODO: Why use render here? */}
          {/* <Route exact path="/:category" render={() => <ListPage />} /> */}
          {/* <Route path={"/"} render={() => <ListPage />} /> */}
          <Route
            exact
            path="/:category"
            render={() => <SimpleListPage handleChange={handleChange} />}
          />
          <Route
            path={"/"}
            render={() => <SimpleListPage handleChange={handleChange} />}
          />
        </Switch>
      </StyledDiv>
    </>
  );
});

export default MainContent;

const StyledDiv = styled.div`
  margin-top: ${(props) => props.theme.spacing(4)};
`;

const categories = [
  { name: "Girls", url: "girls", id: 0 },
  { name: "Boys", url: "boys", id: 1 },
  { name: "Trans", url: "trans", id: 2 },
];
