import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@mui/material";

const common = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 375,
      md: 744,
      lg: 950,
      xl: 1128,
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    h1: {
      fontSize: "4.5rem",
    },
    h2: {
      fontSize: "3.5rem",
    },
    h3: {
      fontSize: "2.5rem",
    },
    body1: {
      fontSize: "0.9rem",
    },
    body2: {
      fontSize: "0.8rem",
    },
  },
  shadows: Array(25).fill("none"),
  shape: {
    borderRadius: 3,
    secondaryRadius: 8,
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiTooltip: {
      arrow: true,
    },
    MuiButton: {
      disableElevation: true,
      size: "small",
    },
    MuiButtonGroup: {
      size: "small",
    },
    MuiCheckbox: {
      size: "small",
    },
    MuiFab: {
      size: "small",
    },
    MuiFormControl: {
      margin: "dense",
      size: "small",
    },
    MuiFormHelperText: {
      margin: "dense",
    },
    MuiIconButton: {
      size: "small",
    },
    MuiInputBase: {
      margin: "dense",
    },
    MuiInputLabel: {
      margin: "dense",
    },
    MuiRadio: {
      size: "small",
    },
    // MuiSwitch: {
    //   size: "small",
    // },
    MuiTextField: {
      margin: "dense",
      size: "small",
    },
  },
  // MuiSwitch: {
  //   root: {
  //     width: 56,
  //     height: 26,
  //     padding: 0,
  //     margin: 8,
  //   },
  //   switchBase: {
  //     padding: 1,
  //     "&$checked, &$colorPrimary$checked, &$colorSecondary$checked": {
  //       transform: "translateX(30px)",
  //       color: "#fff",
  //       "& + $track": {
  //         opacity: 1,
  //         border: "none",
  //       },
  //     },
  //   },
  //   thumb: {
  //     width: 24,
  //     height: 24,
  //   },
  //   track: {
  //     borderRadius: 13,
  //     border: "1px solid #bdbdbd",
  //     backgroundColor: "#fafafa",
  //     opacity: 1,
  //     transition:
  //       "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  //   },
  // },
  // MuiAppBar: {
  //   colorInherit: {
  //     backgroundColor: "#FFF",
  //     color: "#000",
  //   },
  // },
  // },
});

export default common;
