import styled from "@emotion/styled";
import { AppBar, Toolbar } from "@mui/material";

import Branding from "../../molecules/Branding";

const Header = ({ headerRef }) => {
  return (
    <StyledGrowDiv>
      <StyledAppBar position="fixed">
        <div ref={headerRef}>
          <Toolbar>
            <Branding />
          </Toolbar>
        </div>
      </StyledAppBar>
    </StyledGrowDiv>
  );
};

export default Header;

const StyledGrowDiv = styled.div`
  flex-grow: 1;
`;

const StyledAppBar = styled(AppBar)`
  background-color: #fff;
`;
