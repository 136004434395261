// import makeStyles from '@mui/styles/makeStyles';

import styled from "@emotion/styled";

import FooterLinks from "../../molecules/FooterLinks";

const Footer = () => {
  return (
    <StyledFooterContainer>
      <FooterLinks />
    </StyledFooterContainer>
  );
};

export default Footer;

const StyledFooterContainer = styled.div`
  display: flex;
  margin: ${(props) => props.theme.spacing(7)};
  flex-direction: column;
`;
